<template>
  <div class="panel-inr-box">
    <v-row class="no-gutters elevation-0">
      <v-dialog :attach="true" hide-overlay v-model="imageSlider" max-width="600px">
        <v-carousel class="carousel-wrapper">
          <span class="carousel-close-icon" @click="imageSlider = false">
            <v-icon>mdi-close</v-icon>
          </span>
          <v-carousel-item v-for="(item, i) in images" :key="i">
            <img :src="item.url" style="width: 600px; height: auto" :alt="item.url" eager />
          </v-carousel-item>
        </v-carousel>
      </v-dialog>

      <v-col cols="auto" class="flex-grow-1 flex-shrink-0" v-if="!searchResult && !inApplicationView" v-cloak>
        <div class="box-top-wrap d-flex align-center justify-space-between">
          <div>
            <h3 class="box-title green-text">
              {{ $t("navbar.searchInventory") }}
            </h3>
          </div>
          <v-spacer />
        </div>
        <div class="custom-card vehicle-car">
          <div class="light-green-bg">
            <v-radio-group v-model="data.inventoryType" @change="resetData">
              <div class="interchange-radio">
                <v-radio class="pr-3 pt-1" :label="$t('searchInventory.redLineInventory')" value="redLine"
                  selected></v-radio>
                <!-- <v-radio
                    class="pr-4 pb-1"
                    :label="$t('searchInventory.thirdPartyInventory')"
                    value="thirdParty"
                  ></v-radio> -->
              </div>
            </v-radio-group>
          </div>
          <div class="custom-box interchange-box">
            <v-row class="filterField" v-if="data.inventoryType === 'redLine'">
              <v-col cols="12" md="12">
                <v-row>
                  <v-col cols="12" md="12" class="pb-0">
                    <label class="form-label">{{ $t('interchange.modelYear') }}</label>
                    <v-autocomplete height="45" v-model="beginYear" :items="yearList" item-text="CarlineYear"
                      item-value="CarlineYear" @change="onChangeYear" clearable dense solo hide-details
                      :placeholder="$t('interchange.modelYear')" :loading="modelYearLoading"></v-autocomplete>
                  </v-col>
                  <v-col cols="6" md="6">
                    <label class="form-label">{{ $t('interchange.hollanderMake') }}</label>
                    <v-autocomplete :disabled="!beginYear" height="45" v-model="make" :items="makeList"
                      item-text="label" item-value="MfrName" return-object @change="fetchModel" clearable dense solo
                      hide-details :placeholder="$t('interchange.hollanderMake')"
                      :loading="modelLoading"></v-autocomplete>
                    <span class="note-text">Chevrolet, Cadillac, Buick are now GENERAL MOTORS</span>
                  </v-col>
                  <v-col cols="6" md="6">
                    <label class="form-label">{{ $t('interchange.hollanderModel') }}</label>
                    <v-autocomplete :disabled="!make" height="45" v-model="model" :items="modelList" item-text="ModelNm"
                      item-value="ModelNm" return-object @change="fetchPartType" clearable dense solo hide-details
                      :placeholder="$t('interchange.hollanderModel')" :loading="modelLoading"></v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="12" class="pt-0">
                <label class="form-label">{{ $t('interchange.partType') }}</label>
                <v-autocomplete :disabled="!model" height="45" v-model="partType" :items="pType" item-text="Description"
                  item-value="id" @change="searchInventory(null, 'withoutInterchange')" return-object clearable dense
                  solo hide-details :placeholder="$t('interchange.partType')"
                  :loading="partTypeLoading"></v-autocomplete>
              </v-col>
            </v-row>

            <v-row class="filterField" v-if="data.inventoryType === 'thirdParty'">
              <v-col cols="12" md="12">
                <v-row>
                  <v-col cols="12" md="12" class="pt-0">
                    <label class="form-label">{{ $t('searchInventory.partCodeName') }}</label>
                    <v-autocomplete height="45" :items="partCodeName" v-model="oldData.partNameLabel"
                      :error-messages="partCodeNameErrors" item-text="label" item-value="label"
                      @change="setSelectedPartName" clearable dense solo hide-details
                      :placeholder="$t('searchInventory.partCodeName')"></v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="12" class="pt-0">
                    <label class="form-label">{{ $t('searchInventory.partCodeName') }}</label>
                    <v-autocomplete :disabled="!oldData.partName" height="50" :items="oldInventoryModel"
                      v-model="oldData.model" :error-messages="modelThirdPartyErrors"
                      :label="$t('searchInventory.model')" :attach="true" clearable outlined dense chips
                      hide-details></v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="12" class="pt-0">
                    <label class="form-label">{{ $t('searchInventory.year') }}</label>
                    <v-autocomplete :disabled="!oldData.model" height="45" :items="oldInventoryYear"
                      v-model="oldData.year" :error-messages="yearThirdPartyErrors" :label="$t('searchInventory.year')"
                      :attach="true" clearable dense solo hide-details></v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="12" class="pt-0">
                    <v-btn :disabled="!oldData.partName ||
                      !oldData.model ||
                      !oldData.year ||
                      oldData.year.length === 0
                      " height="40" class="btn customBtn pattern-btn
                        text-capitalize white-text btn-submit" @click="searchOldInventory" :loading="loading"><span>{{
                          $t("searchInventory.submit") }}</span>
                    </v-btn>
                  </v-col>

                </v-row>
              </v-col>
            </v-row>

            <v-row class="mt-0">
              <v-progress-linear v-if="interchangeResultLoading" indeterminate color="teal"></v-progress-linear>
              <v-col cols="12" md="12" class="pt-0" v-if="
                interchangeResult.length &&
                !inApplicationView &&
                applicationResult.length === 0
              ">
                <h2 v-if="manufacturer.MfrName">
                  {{
                    $t("interchange.manufacturer") +
                    " : " +
                    manufacturer.MfrName
                  }}
                </h2>
                <v-list flat>
                  <v-list-item-group color="primary" multiple>
                    <template v-for="(item, index) in interchangeResult">
                      <v-list-item v-bind:key="item.SeqNbr" @click="searchInventory(item.InterchangeNumber)">
                        <v-list-item-content :class="!item.InterchangeNumber ? 'title' : ''
                          ">
                          <v-list-item-title v-text="item.Application
                            ? item.Application
                            : item.TreeLevel === 1
                              ? partType.Description
                              : ''
                            " :class="`ml-${item.TreeLevel * 3
                              } interchange-v-list`"></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action class="interchange-v-list" v-if="item.InterchangeNumber"><span>{{
                          item.InterchangeNumber }}</span>
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider v-if="index < interchangeResult.length - 1" :key="index"></v-divider>
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-col>
              <v-col v-else-if="
                interchangeResult.length === 0 &&
                partType.PartType &&
                !interchangeResultLoading
              ">
                <p style="font-size: 18px;">{{ $t("interchange.noResult") }}</p>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
      
      <v-col cols="auto" class="flex-grow-1 flex-shrink-0" v-if="searchResult && inApplicationView" v-cloak>
        <div class="box-top-wrap d-flex align-center justify-space-between">
          <div class="item-row">
            <button @click="back" class="text-capitalize
              btn customBtn pattern-btn
              white--text
            " elevation="0">
              <span>{{ $t("searchInventory.back") }}</span>
            </button>
            <div>
              <h3 class="box-title green-text">
                {{ $t("searchInventory.title") }}
              </h3>
              <h4 class="green--text text--darken-4" v-if="data.inventoryType !== 'thirdParty'">
                {{
                  (beginYear ? beginYear : "") +
                  " " +
                  ((make && model) ? make.MfrName + " " + model.ModelNm : "") +
                  " " +
                  (partType ? partType.Description : "")
                }}
              </h4>
              <h4 class="green--text text--darken-4" v-if="data.inventoryType === 'thirdParty'">
                {{
                  oldData.partName
                    ? oldData.partName
                    : "" + " " + oldData.model
                      ? oldData.model
                      : "" + " " + oldData.year
                        ? oldData.year
                        : ""
                }}
              </h4>
            </div>
          </div>
          <v-spacer />
          <h4 class="green--text text--darken-4">
            {{ $t("searchInventory.NoOfInventory") }}:&nbsp;
          </h4>
          <h2 v-if="data.inventoryType !== 'thirdParty'" class="green--text text--darken-4 ml-2 pl-2 pr-2"
            style="border: 2px solid #1b5e20">
            {{ metaInfo.total || 0 }}
          </h2>
          <h2 v-if="data.inventoryType === 'thirdParty'" class="green--text text--darken-4 ml-2 pl-2 pr-2"
            style="border: 2px solid #1b5e20">
            {{ metaInfo.total || 0 }}
          </h2>
        </div>
        <div class="custom-card" v-if="data.inventoryType !== 'thirdParty'">
          <div class="custom-box">
            <v-data-table :headers="headers" :disable-sort="true" :items="inventories" item-key="id" :single-expand="singleExpand"
              :expanded.sync="expanded" fixed-header show-expand class="color-light-green booklog-table-wrapper"
              :loading="loading" :page.sync="page" :items-per-page="itemsPerPage" @page-count="pageCount = $event"
              hide-default-footer>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <v-container fluid class="down-top-padding dashboardwrapper">
                    <v-row>
                      <v-col cols="12" lg="6">
                        <v-card outlined>
                          <v-layout>
                            <h4 class="p-5">
                              {{ $t("searchInventory.dismantlerInfo") }}:
                            </h4>
                          </v-layout>
                          <v-layout>
                            <h3 class="p-5 pb-6">
                              {{ item.company_name }}
                            </h3>
                          </v-layout>
                          <v-layout>
                            <h4 class="p-5">
                              {{ $t("searchInventory.address") }}:
                            </h4>
                          </v-layout>
                          <v-layout>
                            <h3 class="p-5 pb-6">
                              {{ item.address }}
                            </h3>
                          </v-layout>
                          <v-layout>
                            <h4 class="p-5">
                              {{ $t("searchInventory.sourceTel") }}:
                            </h4>
                          </v-layout>
                          <v-layout>
                            <h3 class="p-5">
                              {{ item.sourceTel | USFormatNumber }}
                            </h3>
                          </v-layout>
                        </v-card>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-card outlined>
                          <v-layout>
                            <h4 class="p-5">
                              {{ $t("searchInventory.stock") }}:
                            </h4>
                          </v-layout>
                          <v-layout>
                            <h3 class="p-5 pb-6">
                              {{ item.stock }}
                            </h3>
                          </v-layout>
                          <v-layout>
                            <h4 class="p-5">
                              {{ $t("searchInventory.miles") }}:
                            </h4>
                          </v-layout>
                          <v-layout>
                            <h3 class="p-5 pb-6">
                              {{ item.miles >= 1 ? item.formatted_miles : '' }}
                            </h3>
                          </v-layout>
                          <v-layout>
                            <h4 class="p-5">
                              {{ $t("searchInventory.warranty") }}/{{ $t("searchInventory.partGrade") }}:
                            </h4>
                          </v-layout>
                          <v-layout>
                            <h3 class="p-5 pb-6">
                              {{ item.warranty_days }}
                            </h3>
                          </v-layout>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </td>
              </template>
              <template v-slot:item.year="{ item }">
                <span :class="item.type">
                  {{ item.year }} {{ item.make }} {{ item.model }}
                </span>
              </template>
              <template v-slot:item.dismantler="{ item }">
                <span :class="item.type">{{ item.dismantler }}</span>
              </template>
              <template v-slot:item.price="{ item }">
                <span :class="item.type">{{
                  item.price | USCurrencyfilter
                }}</span>
              </template>
              <template v-slot:item.interchange="{ item }">
                <span :class="item.interchange_code">
                  {{ item.interchange_code }}
                </span>
              </template>
              <template v-slot:item.engine_name="{ item }">
                <span :class="item.engine_name">
                  {{ item.engine_name }}
                </span>
              </template>
              <template v-slot:item.type="{ item }">
                <span :class="item.type">
                  {{ item.inventoryType >= 1 ? item.inventoryType : '' }}
                </span>
              </template>
              <template v-slot:item.images="{ item }">
                <a class="mr-2" v-if="item.images.length > 0" @click="
                  imageSlider = true;
                images = item.images;
                ">
                  <v-icon>mdi-camera</v-icon>
                </a>
                <a class="mr-2" @click="
                  emailDialog = true;
                id = item.id;
                ">
                  <v-icon>mdi-email</v-icon> </a>&nbsp;
                <a class="mr-2" @click="
                  phoneDialog = true;
                id = item.memberId;
                ">
                  <v-icon>mdi-share-variant</v-icon> </a>&nbsp;
                <a class="mr-2" target="blank" @click="
                  address = item.address;
                showGoogleMap = true;
                ">
                  <v-icon>mdi-map-marker</v-icon>
                </a>
              </template>
              <template v-slot:no-data>
                {{ $t('searchInventory.noResult') }}
              </template>
            </v-data-table>
            <div class="text-center">
              <v-pagination v-model="page" :length="metaInfo.last_page" @input="searchInventory"></v-pagination>
            </div>
          </div>
        </div>
        <div class="custom-card" v-if="data.inventoryType === 'thirdParty'">
          <div class="custom-box">
            <v-data-table :header-props="{ sortIcon: null }" :headers="headersOldInventory" item-key="id"
              :items="oldInventoryList" :single-expand="singleExpand" :expanded.sync="expanded" fixed-header show-expand
              class="color-light-green booklog-table-wrapper" :loading="loading" :page.sync="page"
              :items-per-page="itemsPerPage" @page-count="pageCount = $event" hide-default-footer>
              <template v-slot:item.dismantler="{ item }">
                <span :class="item.is_my_inventory
                  ? 'myInventory'
                  : 'networkInventory'
                  ">
                  {{
                    item.is_my_inventory
                      ? "My Store Inventory"
                      : (item &&
                        item.user &&
                        item.user.user_detail &&
                        item.user.name +
                        ", " +
                        item.user.user_detail.city +
                        "-" +
                        item.user.user_detail.zip_code) ||
                      "N/A"
                  }}
                </span>
              </template>
              <template v-slot:item.price="{ item }">
                <span :class="item.is_my_inventory
                  ? 'myInventory'
                  : 'networkInventory'
                  ">
                  {{ item.sale_price || "0" | USCurrencyfilter }}
                </span>
              </template>
              <!--                  <template v-slot:item.interchange="{ item }">
                    {{ (item && item.interchange_description) || "N/A" }}
                  </template>-->
              <template v-slot:item.grade="{ item }">
                <span :class="item.is_my_inventory
                  ? 'myInventory'
                  : 'networkInventory'
                  ">{{ (item && item.grade) || "N/A" }}</span>
              </template>
              <template v-slot:item.distance="{ item }">
                <span :class="item.is_my_inventory
                  ? 'myInventory'
                  : 'networkInventory'
                  ">{{ (item && item.distance) || "N/A" }}</span>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <v-container fluid class="down-top-padding dashboardwrapper">
                    <v-row>
                      <v-col cols="12" lg="6">
                        <v-card outlined>
                          <v-layout>
                            <h4 class="p-5">
                              {{ $t("searchInventory.dismantlerInfo") }}:
                            </h4>
                          </v-layout>
                          <v-layout>
                            <h3 class="p-5 pb-6">
                              {{
                                (item &&
                                  item.user &&
                                  item.user.user_detail &&
                                  item.user.user_detail.company_name) ||
                                "N/A"
                              }}
                            </h3>
                          </v-layout>
                          <v-layout>
                            <h4 class="p-5">
                              {{ $t("searchInventory.address") }}:
                            </h4>
                          </v-layout>
                          <v-layout>
                            <h3 class="p-5 pb-6">
                              {{
                                (item &&
                                  item.user &&
                                  item.user.user_detail &&
                                  item.user.user_detail.full_address) ||
                                "N/A"
                              }}
                            </h3>
                          </v-layout>
                          <v-layout>
                            <h4 class="p-5">
                              {{ $t("searchInventory.sourceTel") }}:
                            </h4>
                          </v-layout>
                          <v-layout>
                            <h3 class="p-5">
                              {{
                                item &&
                                item.user &&
                                item.user.user_detail &&
                                item.user.user_detail.company_phone
                                | USFormatNumber
                              }}
                            </h3>
                          </v-layout>
                        </v-card>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-card outlined>
                          <v-layout>
                            <h4 class="p-5">
                              {{ $t("searchInventory.vin") }} /
                              {{ $t("searchInventory.yard") }} /
                              {{ $t("searchInventory.interchange") }}:
                            </h4>
                          </v-layout>
                          <v-layout>
                            <h3 class="p-5 pb-6">
                              {{ item.vin || "N/A" }} /
                              {{ item.location || "N/A" }} /
                              {{ item.interchange || "N/A" }}
                            </h3>
                          </v-layout>
                          <v-layout>
                            <h4 class="p-5">
                              {{ $t("searchInventory.stock") }} /
                              {{ $t("searchInventory.miles") }}:
                            </h4>
                          </v-layout>
                          <v-layout>
                            <h3 class="p-5 pb-6">
                              {{ item.stoke_number || "N/A" }} /
                              {{
                                (item.miles &&
                                  item.miles
                                    .toString()
                                    .replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )) ||
                                "N/A"
                              }}
                            </h3>
                          </v-layout>
                          <v-layout>
                            <h4 class="p-5">
                              {{ $t("searchInventory.description") }}:
                            </h4>
                          </v-layout>
                          <v-layout>
                            <h3 class="p-5 pb-6">
                              {{ item.description || "N/A" }}
                            </h3>
                          </v-layout>
                          <v-layout>
                            <h4 class="p-5">
                              {{ $t("searchInventory.interchangeDesc") }}:
                            </h4>
                          </v-layout>
                          <v-layout>
                            <h3 class="p-5 pb-6">
                              {{ item.interchange_description || "N/A" }}
                            </h3>
                          </v-layout>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </td>
              </template>
              <template v-slot:item.images="{ item }">
                <a class="mr-2" @click="
                  emailDialog = true;
                id = item.id;
                ">
                  <v-icon>mdi-email</v-icon> </a>&nbsp;
                <a class="mr-2" @click="
                  phoneDialog = true;
                id = item.user_id;
                ">
                  <v-icon>mdi-share-variant</v-icon> </a>&nbsp;
                <a class="mr-2" target="blank" @click="
                  address = item.location;
                showGoogleMap = true;
                ">
                  <v-icon>mdi-map-marker</v-icon>
                </a>
              </template>
              <template v-slot:no-data>
                {{ $t('searchInventory.noResult') }}
              </template>
            </v-data-table>
            <div class="text-center">
              <v-pagination v-model="page" @input="searchOldInventory" :length="metaInfo.last_page"
                :total-visible="5"></v-pagination>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-dialog hide-overlay v-model="showGoogleMap" v-if="showGoogleMap" max-width="600px" :attach="true">
      <google-map :address="address" />
    </v-dialog>
    <v-dialog hide-overlay :attach="true" v-model="emailDialog" max-width="400px"
      :content-class="'hide-overflow-y image-slider'">
      <v-card>
        <v-card-title>
          {{ $t("searchInventory.emailDialogTitle") }}
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="email" @input="$v.email.$touch()" @blur="$v.email.$touch()"
            :error-messages="emailErrors" :label="$t('bookLog.email')" outlined></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn height="40" large class="red darken-4 red-gradient white--text text-capitalize"
            @click="emailDialog = !emailDialog">
            {{ $t("bookLog.close") }}
          </v-btn>
          <v-btn height="40" small color="green darkan-4 green-gradient white--text" :loading="saving"
            :disabled="($v.email.$invalid && $v.email.$anyDirty) || saving" @click="sendPdfViaEmail()">
            {{ $t("bookLog.sendEmail") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog hide-overlay :attach="true" v-model="phoneDialog" max-width="400px"
      :content-class="'hide-overflow-y image-slider'">
      <v-card>
        <v-card-title>
          {{ $t("searchInventory.phoneDialogTitle") }}
        </v-card-title>
        <v-card-text>
          <vue-tel-input class="mr-3" style="height: 50px; width: 100%"
            :style="phone && phoneError ? 'border-color: red;' : ''" elevation="0" dense v-model="phone"
            :error-messages="phoneError" validCharactersOnly @blur="$v.phone.$touch()" :inputOptions="{
              maxlength: 14,
              placeholder: $t('membersDirectory.cellularNumber'),
            }" :dynamicPlaceholder="true" :disabledFormatting="false" defaultCountry="US"
            :onlyCountries="['US', 'CO', 'MX', 'CA']" />
          <div v-if="phone" style="color: red">{{ phoneError }}</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn height="40" large class="red darken-4 red-gradient white--text text-capitalize"
            @click="phoneDialog = !phoneDialog">
            {{ $t("bookLog.close") }}
          </v-btn>
          <v-btn height="40" medium @click="shareMemberInfo()"
            :disabled="!phone || ($v.phone.$invalid && $v.phone.$dirty)" :loading="loading" dense
            class="green darkan-4 green-gradient white--text text-capitalize" elevation="0">
            {{ $t("membersDirectory.share") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog hide-overlay :attach="true" v-model="errorDailog" max-width="700px"
      :content-class="'hide-overflow-y image-slider'">
      <v-card>
        <v-card-text class="pa-0 flex-grow-1 overflow-y-auto custom-chat-height">
          <div class="pa-2">
            <v-card-title style="flex-direction: column;"> {{ $t("searchInventory.noPartResultFound") }}</v-card-title>
            <v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn height="40" large class="red darken-4 red-gradient white--text text-capitalize" @click="back">
                  {{ $t("invoice.close") }}
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-container>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { find, some, join, map, reverse, get } from "lodash";
import { ITEMS_PER_PAGE } from "../constants/common";
import { email, required } from "vuelidate/lib/validators";
import GoogleMap from "../components/common/GoogleMap";
import { VueTelInput } from "vue-tel-input";

export default {
  name: "SearchInventory",
  components: {
    GoogleMap,
    VueTelInput,
  },
  data() {
    return {
      make: null,
      model: null,
      modelList: [],
      makeList: [],
      yearList: [],
      email: "",
      id: "",
      address: "",
      showGoogleMap: false,
      data: {
        inventoryType: "redLine",
      },
      itemName: null,
      interchangeNumber: null,
      mfrCd: null,
      beginYear: null,
      partType: {},
      interchangeResult: [],
      applicationResult: [],
      loading: false,
      manufacturerLoading: false,
      modelLoading: false,
      modelYearLoading: false,
      partTypeLoading: false,
      interchangeResultLoading: false,
      inApplicationView: false,
      searchByInterchangePartType: {},
      errors: {
        phone: null,
        partName: null,
        make: null,
        model: null,
        year: null,
        partCodeName: null,
      },
      oldData: {
        model: "",
        year: "",
        partNameLabel: "",
        partName: "",
      },
      inventories: [],
      searchResult: false,
      singleExpand: true,
      expanded: [],
      images: [],
      imageSlider: false,
      emailDialog: false,
      saving: false,
      phoneDialog: false,
      phone: null,
      itemsPerPage: 15,
      pageCount: 0,
      page: 1,
      errorDailog: false,
    };
  },
  validations: {
    oldData: {
      partNameLabel: { required },
      model: { required },
      year: { required },
    },

    email: { required, email },
    phone: {
      required,
      usFormat: (value) => {
        return /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/.test(value);
      },
    },
  },
  computed: {
    ...mapGetters({
      yearOptions: "hollander/getYears",
      makeOptions: "hollander/getMake",
      modelOptions: "hollander/getModel",
      manufacturer: "interchange/getManufacturer",
      carline: "interchange/getCarline",
      modelYears: "interchange/getModelYears",
      pType: "interchange/getPType",
      // oldInventoryModel: "searchInventory/getOldInventoryModel",
      // partCodeName: "searchInventory/getPartCodeName",
      indexListApp: "interchange/getIndexListApp",
      applications: "interchange/getApplications",
      metaInfo: "searchInventory/getInventoryMetaInfo",
      inventoryList: "searchInventory/getInventories",
      // oldInventoryList: "searchInventory/getOldInventoryList",
      // oldInventoryYear: "searchInventory/getOldInventoryYear",
    }),
    headers() {
      return [
        { text: "", value: "data-table-expand", width: "5%" },
        {
          text: this.$t("searchInventory.yearMakeModel"),
          align: "left",
          value: "year",
          width: "17%",
        },
        {
          text: this.$t("searchInventory.dismantler"),
          align: "left",
          sortable: false,
          value: "dismantler",
          width: "16%",
        },
        {
          text: this.$t("searchInventory.price"),
          align: "left",
          value: "price",
          width: "12%",
        },
        {
          text: this.$t("searchInventory.interchange"),
          align: "left",
          sortable: false,
          value: "interchange_code",
          width: "10%",
        },
        {
          text: this.$t("searchInventory.engineName"),
          align: "left",
          sortable: false,
          value: "engine_name",
          width: "15%",
        },
        {
          text: this.$t("searchInventory.distanceInMiles"),
          align: "left",
          sortable: false,
          value: "type",
          width: "10%",
        },
        {
          text: this.$t("searchInventory.actions"),
          align: "left",
          sortable: false,
          value: "images",
          width: "15%",
        },
      ];
    },
    headersOldInventory() {
      return [
        { text: "", value: "data-table-expand", width: "8%" },
        {
          text: this.$t("searchInventory.dismantler"),
          align: "left",
          value: "dismantler",
          width: "20%",
        },
        {
          text: this.$t("searchInventory.salesPrice"),
          align: "left",
          value: "price",
          width: "20%",
        },
        {
          text: this.$t("searchInventory.partGrade"),
          align: "left",
          value: "grade",
          width: "10%",
        },
        {
          text: this.$t("searchInventory.distanceInMiles"),
          align: "left",
          value: "distance",
          width: "10%",
        },
        {
          text: this.$t("searchInventory.actions"),
          align: "left",
          value: "images",
          width: "15%",
        },
        /*{
          text: this.$t("searchInventory.description"),
          align: "left",
          value: "description",
          width: "20%",
        },
        {
          text: this.$t("searchInventory.interchangeDesc"),
          align: "left",
          value: "interchange",
          width: "20%",
        },*/
      ];
    },
    partCodeNameErrors() {
      const errors = [];

      if (!this.$v.oldData.partNameLabel.required) {
        errors.push(this.$t("searchInventory.validations.partNameIsRequired"));
        return errors;
      }
      if (this.errors && this.errors.length) {
        return this.errors;
      }

      return errors;
    },
    modelThirdPartyErrors() {
      const errors = [];

      if (!this.$v.oldData.model.required) {
        errors.push(this.$t("searchInventory.validations.modelIsRequired"));
        return errors;
      }
      if (this.errors && this.errors.length) {
        return this.errors;
      }

      return errors;
    },
    yearThirdPartyErrors() {
      const errors = [];

      if (!this.$v.oldData.year.required) {
        errors.push(this.$t("searchInventory.validations.yearIsRequired"));
        return errors;
      }
      if (this.errors && this.errors.length) {
        return this.errors;
      }

      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required &&
        errors.push(this.$t("bookLog.validations.emailIsRequired"));
      !this.$v.email.email &&
        errors.push(this.$t("bookLog.validations.emailIsInvalid"));
      return errors;
    },
    phoneError() {
      let error = null;
      if (this.$v.phone.$dirty && !this.$v.phone.required) {
        error = this.$t("requiredValidation", {
          field: this.$t("membersDirectory.telephone"),
        });
      }

      if (!this.$v.phone.usFormat) {
        error = this.$t("leads.representativePhoneIsInvalid", {
          field: this.$t("membersDirectory.telephone"),
        });
      }

      if (this.errors && this.errors.phone) {
        error = this.errors.phone;
      }
      return error;
    },
  },
  async mounted() {
    await this.setYear();
    await this.setMake();
    if (
      get(this.$route.query, "year") &&
      get(this.$route.query, "partName") &&
      get(this.$route.query, "make") &&
      get(this.$route.query, "model")
    ) {
      this.beginYear = await this.getBeginYearFromQueryParam();

      const queryMake = get(this.$route.query, "make");
      let make = null;
      let queryModel = null
      if (get(this.$route.query, "model")) {
        queryModel = get(this.$route.query, "model").split(" ")[0];
      }
      let model = null;
      this.makeList.map(function (element) {
        if (element.MfrName === queryMake) {
          make = element;
        }
      });

      if (make) {
        this.make = make;
        await this.setModel();
        await this.modelList.map(function (element) {
          if (element.ModelNm === queryModel.toUpperCase()) {
            model = element;
          }
        });

        if (model) {
          this.model = model;
          await this.fetchPartType();
          if (get(this.$route.query, "partName")) {
            let partName = await find(this.pType, (partType) => {
              return partType.Description.toLowerCase() === get(this.$route.query, "partName").toLowerCase();
            });
            this.partType = partName ? partName : {};
          }

          this.partType ? await this.fetchIndexListApp() : null;
        }
      }
    } else {
      // await this.getPartCodeName();
      // await this.getOldInventoryModel();
      // await this.getOldInventoryYear();
    }
  },
  methods: {
    ...mapActions({
      getYears: "hollander/getYears",
      getMake: "hollander/getMake",
      getModel: "hollander/getModel",
      getManufacturer: "interchange/getManufacturer",
      getCarline: "interchange/getCarline",
      getModelYears: "interchange/getModelYears",
      getPType: "interchange/getPType",
      getIndexListApp: "interchange/getIndexListApp",
      getApplications: "interchange/getApplications",
      getInventoryList: "searchInventory/getInventories",
      // getPartCodeName: "searchInventory/getPartCodeName",
      // getOldInventoryModel: "searchInventory/getOldInventoryModel",
      // getOldInventoryYear: "searchInventory/getOldInventoryYear",
      // getOldInventoryList: "searchInventory/getOldInventoryList",
      shareOldCarProfileInfoViaMail:
        "searchInventory/shareOldCarProfileInfoViaMail",
      sendPdf: "searchInventory/sendPdf",
      shareMember: "members/shareMember",
      getYear: "bookLog/getYear",
    }),

    resetData() {
      this.itemName = null;
      this.interchangeNumber = null;
      this.mfrCd = null;
      this.make = null;
      this.model = null;
      this.beginYear = null;
      this.partType = {};
      this.interchangeResult = [];
      this.applicationResult = [];
      this.manufacturerLoading = false;
      this.modelLoading = false;
      this.modelYearLoading = false;
      this.partTypeLoading = false;
      this.interchangeResultLoading = false;
      this.inApplicationView = false;
      this.searchByInterchangePartType = {};

      this.oldData.model = "";
      this.oldData.year = "";
      this.oldData.partNameLabel = "";
      if (this.data.inventoryType === "thirdParty") {
        this.fetchPartType();
      }
    },

    async fetchManufacturers() {
      this.inApplicationView = false;
      this.manufacturerLoading = true;
      try {
        await this.getManufacturer({ MfrCd: this.mfrCd });
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.manufacturerLoading = false;
      }
    },

    async onChangeYear() {
      this.make = null;
      this.model = null;
      this.partType = {};
      this.interchangeResult = [];
    },

    async fetchModel() {
      this.model = null;
      this.partType = {};
      await this.setModel();
    },

    async setYear() {
      this.yearList = [];
      try {
        this.$store.commit("setLoader", true);
        await this.getYears();
        this.yearList = await [...this.yearOptions];
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.$store.commit("setLoader", false);
      }
    },

    async setMake() {
      this.makeList = [];
      try {
        this.$store.commit("setLoader", true);
        await this.getMake({});
        this.makeList = await [...this.makeOptions];
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.$store.commit("setLoader", false);
      }
    },

    async setModel() {
      this.modelList = [];
      try {
        this.$store.commit("setLoader", true);
        await this.getModel({
          MfrCd: this.make.MfrCd,
        });
        this.modelList = await [...this.modelOptions];
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    async fetchPartType() {
      this.partTypeLoading = true;
      this.partType = {};
      this.interchangeResult = [];
      if (this.make && this.model && this.beginYear) {
        try {
          this.mfrCd = this.make.MfrCd;
          await this.fetchManufacturers();
          await this.getPType();
        } catch ({ message }) {
          this.apiErrorMessage = message;
        } finally {
          this.partTypeLoading = false;
        }
      } else if (this.data.inventoryType === "thirdParty") {
        await this.getPType();
        this.partTypeLoading = false;
      } else {
        this.partTypeLoading = false;
      }
    },
    async fetchIndexListApp() {
      this.inApplicationView = false;
      this.interchangeResultLoading = true;
      this.interchangeResult = [];
      this.applicationResult = [];
      try {
        await this.getIndexListApp({
          MfrCd: this.make.MfrCd,
          ModelNm: this.model.ModelNm,
          BeginYear: this.beginYear,
          PartType: this.partType.PartType,
        });
        this.interchangeResult = this.indexListApp;
        if (this.indexListApp.length <= 0) {
          await this.searchInventory(null, 'withoutInterchange');
        }
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.interchangeResultLoading = false;
      }
    },
    async setApplications(item) {
      if (item.PartType && item.IntchNbr) {
        this.inApplicationView = true;
        this.interchangeResultLoading = true;
        this.applicationResult = [];
        let i = item.TreeLevel;
        let SeqNbr = item.SeqNbr - 1;
        const resultParentItems = [];

        for (i; i >= 1; i--) {
          const data = some(this.interchangeResult, (result) => {
            return !result.LRFlag && result.SeqNbr === SeqNbr;
          });
          if (data) {
            resultParentItems.push(
              find(this.interchangeResult, (result) => {
                return !result.LRFlag && result.SeqNbr === SeqNbr;
              })
            );
            SeqNbr = SeqNbr - 1;
          } else {
            SeqNbr = SeqNbr - 1;
            const data = find(this.interchangeResult, (result) => {
              return !result.LRFlag && result.SeqNbr === SeqNbr;
            });
            if (data) {
              resultParentItems.push(data);
            }
            SeqNbr = SeqNbr - 1;
          }
        }

        this.itemName =
          join(reverse(map(resultParentItems, "Application")), " ~> ") +
          " ~> " +
          item.Application +
          " ~> " +
          item.InterchangeNumber;

        await this.fetchApplications(
          item.IntchNbr,
          item.PartType,
          item.AlphaExtension
        );
      }
    },

    async fetchApplications(
      InterchangeNumber,
      partType = null,
      AlphaExtension = null
    ) {
      try {
        if (AlphaExtension) {
          await this.getApplications({
            InterchangeNumber: InterchangeNumber + AlphaExtension,
            PartType: partType,
          });
        } else {
          await this.getApplications({
            InterchangeNumber: InterchangeNumber,
            PartType: partType,
          });
        }
        this.applicationResult = this.applications;
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.interchangeResultLoading = false;
      }
    },

    async searchInterchangeNumber() {
      this.inApplicationView = true;
      this.interchangeResultLoading = true;
      this.applicationResult = [];
      await this.fetchApplications(
        this.interchangeNumber,
        this.searchByInterchangePartType.PartType
      );
    },

    getYearFromQueryParam() {
      let year = null;
      if (get(this.$route.query, "year")) {
        year = JSON.parse(get(this.$route.query, "year"));
      }
      return year;
    },

    getBeginYearFromQueryParam() {
      let year = null;
      if (get(this.$route.query, "year")) {
        year = JSON.parse(get(this.$route.query, "year"));
        if (Array.isArray(year)) {
          year = parseInt(year[0]);
        } else {
          year = parseInt(year);
        }
      }
      return year;
    },

    async searchInventory(interchangeNumber = null, from = '') {
      if (interchangeNumber || from === 'withoutInterchange') {
        try {
          this.loading = true;
          this.$store.commit("setLoader", true);
          this.inventories = [];

          await this.getInventoryList({
            make: this.make.MfrName,
            model: this.model.ModelNm,
            years: [this.beginYear],
            part_name: this.partType.Description,
            interchange_number: interchangeNumber,
            page: this.page,
            per_page: ITEMS_PER_PAGE,
          });

          if (this.inventoryList.length === 0) {
            this.$store.commit("setLoader", false);
            this.errorDailog = true;
          } else {
            this.searchResult = true;
            this.inApplicationView = true;
            this.$store.commit("setLoader", false);
            this.inventories = this.inventoryList.map(function (val) {
              return {
                id: val.id,
                memberId:
                  val.car_profile.user && val.car_profile.user.user_detail.id,
                dismantler: val.is_my_inventory
                  ? "My Store Inventory"
                  : val.car_profile.user &&
                  val.car_profile.user.name +
                  ", " +
                  val.car_profile.user.user_detail.city +
                  "-" +
                  val.car_profile.user.user_detail.zip_code,
                price: val.recommended_sale_price,
                interchange_code: val.interchange_code,
                engine_name: val.car_profile.engine_name,
                warranty: (val.warranty_days ? val.warranty_days + " / " : "") + (val.part_grade ? val.part_grade : ''),
                sourceTel:
                  val.car_profile.user &&
                  val.car_profile.user.user_detail.company_phone,
                make: val.car_profile.make,
                type: val.is_my_inventory ? "myInventory" : "networkInventory",
                inventoryType: val.distance,
                model: val.car_profile.model,
                quantity: val.quantity,
                year: val.car_profile.year,
                address:
                  val.car_profile.user && val.car_profile.user.user_detail.address,
                miles: val.miles,
                formatted_miles: val.miles.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                stock: val.car_profile.stock_number,
                company_name:
                  val.car_profile.user &&
                  val.car_profile.user.user_detail.company_name,
                images: val.medias,
              };
            });
            if (this.metaInfo) {
              this.itemsPerPage = parseInt(this.metaInfo.per_page);
              this.pageCount = this.metaInfo.last_page;
            } else {
              this.itemsPerPage = this.inventoryList.length;
              this.pageCount = 1;
            }
          }
        } catch ({ message }) {
          this.errors.partName = message.part_name;
          this.apiErrorMessage = message;
        } finally {
          this.loading = false;
          this.$store.commit("setLoader", false);
        }
      }
    },

    setSelectedPartName(event) {
      this.oldData.partName = get(
        find(this.partCodeName, { label: event }),
        "name"
      );
    },

    async searchOldInventory() {
      console.log("from old search...");
      try {
        this.loading = true;
        await this.getOldInventoryList({
          model: this.oldData.model,
          year: this.oldData.year,
          part_name: this.oldData.partName,
          page: this.page,
          per_page: ITEMS_PER_PAGE,
        });
        this.searchResult = true;
        this.inApplicationView = true;
      } catch ({ message }) {
        this.errors.partCodeName = message.part_name;
        this.apiErrorMessage = message;
      } finally {
        this.loading = false;
      }
    },

    async sendPdfViaEmail() {
      this.$v.email.$touch();
      if (!this.$v.email.$invalid) {
        try {
          this.saving = true;
          if (this.data.inventoryType === "thirdParty") {
            await this.shareOldCarProfileInfoViaMail({
              email: this.email,
              inventory_old_car_profile_id: this.id,
            });
          } else {
            await this.sendPdf({
              email: this.email,
              inventory_car_part_id: this.id,
            });
          }
          this.emailDialog = false;
          this.email = null;
        } catch ({ message }) {
          this.apiErrorMessage = message;
        } finally {
          this.saving = false;
        }
      }
    },

    async shareMemberInfo() {
      this.$v.phone.$touch();
      if (!this.$v.phone.$invalid) {
        this.loading = true;
        try {
          await this.shareMember({
            member_id: this.id,
            phone: this.phone,
          });
          this.phone = null;
          this.phoneDialog = false;
        } catch ({ message }) {
          this.errors.phone = message;
        } finally {
          this.loading = false;
        }
      }
    },

    back() {
      this.inApplicationView = false;
      this.searchResult = false;

      if (this.errorDailog == true) {
        this.errorDailog = false;
      }

      if (this.indexListApp.length <= 0) {
        this.resetData();
      }
    },
  },
};
</script>
<style scoped>
.v-dialog__content {
  position: absolute;
}
</style>
